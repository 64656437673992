/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

// Dark Mode Colours
:root {
    --light-grey: #DBDCDB;
    --dark-grey: #425563;
    --availability-color: #666666;
    --light-availability-color: #b5b5b5;

  --orange-yellow: #FFBF00;
  --orange-yellow-color: #425563;
  --orange-yellow-light: #ffdf80;
}

.flex{
    display: flex;
}

// TODO: Remove this when we update to latest version of primeflex, as they already these clases.
.gap-0 {
    gap: 0;
}

.gap-1 {
    gap: 0.25rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-3 {
    gap: 1rem;
}

.gap-4 {
    gap: 1.5rem;
}

.gap-5 {
    gap: 2rem;
}

.gap-6 {
    gap: 3rem;
}

.gap-7 {
    gap: 4rem;
}

.gap-8 {
    gap: 5rem;
}

// Directive Styling

.rule-error {
    input {
        border: 2px solid var(--error);
    }

    .p-checkbox-box {
        border: 2px solid var(--error) !important;
    }

    select {
        border: 2px solid var(--error);
    }

    .p-inputswitch-slider {
        border: 2px solid var(--error);
    }

    .p-inputswitch::before {
        content: '!';
        font-size: 16px;
        color: var(--error);
        position: relative;
        left: -12px;
        top: 4px;
    }

    .p-dropdown {
        border: 2px solid var(--error);
        &::before {
            content: '!';
            font-size: 16px;
            color: var(--danger);
            position: relative;
            left: -14px;
            top: 6px;
        }
    }
}

.rule-error-direct {
    border: 2px solid var(--error);
}

.rule-error,
.rule-error-direct {
    &::before {
        content: '!';
        font-size: 16px;
        color: var(--error);
        position: relative;
        left: -8px;
    }
}

// This class is used to cancel the icon styles on components that look bad with it
.rule-error-no-icon::before {
    content: none !important;
}

// Directive Styling

.rule-warn {
    input {
        border: 2px solid var(--warning);
    }

    .p-checkbox-box {
        border: 2px solid var(--warning) !important;
    }

    select {
        border: 2px solid var(--warning);
    }

    .p-inputswitch-slider {
        border: 2px solid var(--warning);
    }

    .p-inputswitch::before {
        content: '!';
        font-size: 16px;
        color: var(--warning);
        position: relative;
        left: -12px;
        top: 4px;
    }

    .p-dropdown {
        border: 2px solid var(--warning);
        &::before {
            content: '!';
            font-size: 16px;
            color: var(--warning);
            position: relative;
            left: -14px;
            top: 6px;
        }
    }
}

.rule-warn-direct {
    border: 2px solid var(--warning);
}

.rule-warn,
.rule-warn-direct {
    &::before {
        content: '!';
        font-size: 16px;
        color: var(--warning);
        position: relative;
        left: -8px;
    }
}

// This class is used to cancel the icon styles on components that look bad with it
.rule-warn-no-icon::before {
    content: none !important;
}

body {
    overflow: hidden;
}

.bold{
    font-weight: bold;
}

.break-text {
    word-wrap: break-word;
}

.preserve-whitespace {
    white-space: pre-line;
}

.scroll-padding{
    padding-top: 100px;
}

// Text
.success-text {
    color: var(--success);
}

.error-text {
    color: var(--error);
}

.warning-text {
    color: var(--warning);
}

.big-text{
    font-size: 1.1em;
}

// Backgrounds
.success-bg{
    color: white;
    background-color: var(--success);
}

.error-bg{
    color: white;
    background-color: var(--error);
}

.warning-bg{
    background-color: var(--warning);
}

.freya-link-button {
    all: unset;
    color: var(--primary-color);
    cursor: pointer;
}

.link-outline {
    cursor: pointer;
    padding: 8px;
    border: 2px solid #ffffff00;

    &:hover {
        border: 2px var(--primary-color) solid;
        border-radius: 4px;
    }
}

.disabled {
    filter: brightness(0.9) !important;
    color: var(--surface-d) !important;
    pointer-events: none;
    cursor: st;
}
.p-invalid-password {
    input {
        border: 1px solid var(--error) !important;
    }
}

.collapsable{
    transition-property: max-height;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0, 1);

    .p-grid:first-child {
        margin-top: 0;
    }
}

.collapsed {
    max-height: 0;
    overflow-y: hidden;
    overflow-x: hidden;
}


.p-invalid-input {
    border: 1px solid var(--error) !important;
}

// Removes padding from all the tab elements, necessary for the tabbed pages eg. user. Will break the default style if actually used tho
.p-tabview .p-tabview-panels {
    padding: 0px !important;
    padding-top: 1rem !important;
}

.card-title {
    font-weight: bold;
}

.color-box{
    border:solid 1px var(--dark-grey);
    display : flex;
    justify-content: space-between;
    align-items: center;


}
.button-section {
    button {
        margin: 4px;
    }
}

.large-icon {
    font-size: 1.5rem !important;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.full-width-dropdown {
    .p-dropdown {
        width: 100%;
    }
}
.half-width-dropdown {
    .p-dropdown {
        width: 50%;
    }
}

.full-width-multiselect {
    .p-multiselect {
        width: 100%;
    }
}

.half-width-multiselect {
    .p-multiselect {
        width: 60%;
    }
}


.full-width-calendar {
    .p-calendar {
        width: 100%;
    }
}

.full-width-number-input {
    .p-inputnumber-input{
        width: 100%;
    }
}

.short-number-input {
    .p-inputnumber-input{
        width: 5rem;
    }
}

.full-width-text-input {
    width: 100%;
}

@media screen and (max-width: 700px) {
    .short-number-input {
        .p-inputnumber-input{
            width: 4rem;
        }
    }
}

.mid-width-number-input {
    .p-inputnumber-input {
        width: 8rem;
    }
}

@media screen and (min-width: 990px) and (max-width: 1100px) {
    .mid-width-number-input--adjustable .p-inputnumber-input {
        width: 5.5rem;
    }
}

@media screen and (max-width: 700px) {
    .mid-width-number-input--adjustable .p-inputnumber-input {
        width: 7rem;
    }
}


.space-for-select{
    height: 350px;
}

// Allows Modals to Work Properly if laucnhed in right panel
.layout-wrapper.layout-rightpanel-active .layout-rightpanel {
    transform: none !important;
}

.field-error-message {
    color: var(--error);
    display: block;
}

.spaced-buttons {
    button {
        margin: 4px;
    }
}

// Fixes Expansion Row Width
.p-datatable {
    table {
        table-layout: auto !important;
    }
}

.p-datatable-wrapper {
    overflow-x: scroll;
}

.data-table .p-datatable-wrapper {
    overflow-x: visible;
}

.quick-margin {
    margin: 4px;
}

.black-text {
    color: black;
}

.white-text {
    color: white;
}

.grey-text {
    color: var(#69707A);
}

.confirm-button {
    .p-button {
        color: white !important;

        .p-button-label {
            color: white !important;
        }
    }
}

a.p-button {
    // color: var(--primary-color-text);
}

.color-square{
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border:  var(--text-color-secondary) 1px solid;
    text-align: right;
    float: right;
}

.switch-label-group{
    display: inline-flex;
    align-items: center;
    label {
        margin-left: 4px;
    }
}

// Hidden attribute is overriden by explicit display property, use this instead for more consistent results
.hide {
    display: none !important;
}

// Overrides the default toast location
.p-toast-top-right {
    // Moves it below the top bar
    top: 68px !important;
    // Moves it to the right of the actions button
    right: 120px;
}

// Adds Margin Between Chips
.chip-container {
    p-chip {
        display: inline-block;
        margin: 2px 4px;
    }
}

// Full width chips
.full-width-chips ul.p-chips-multiple-container {
    width: 100%;
}

.tag-container {
    p-tag {
        display: inline-block;
        margin: 2px 4px;
    }

    p-skeleton {
        display: inline-block;
        margin: 2px 4px;
    }
}

.selectable-tag{
    cursor: pointer;
    .p-tag:hover{
        filter: brightness(85%);
    }
}

.no-margin {
    margin: 0px !important;
}

.location-input {
    width: 100%;
}

// puts the google places autocomplete dropdown results above the bootstrap modal 1050 zindex.
.pac-container {
    z-index: 5000 !important;
}

.card {
    overflow: wrap;
}

.cursor-pointer {
    cursor: pointer;
}

.header-card {
    padding-bottom: 0px;
}

.button-container {
    button {
        margin: 0px 4px;
    }
}

.location-orderlist {
    .p-orderlist {
        .p-orderlist-list-container {
            ul {
                padding: 0px !important;
            }

            .p-orderlist-list {
                li {
                    border-top: 2px solid var(--light-grey);
                }
            }
        }

        .p-orderlist-controls {
            display: none;
        }
    }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-radius: 0px !important;
}

.indicator {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--light-grey);
}

.fc-scrollgrid {
    thead {
        position: sticky;
        // top: 60px;
        z-index: 947;

        .fc-scrollgrid-section{
            background-color: var(--card-background);
        }

        .fc-datagrid-cell {
            .fc-datagrid-cell-frame{
                height: 40px !important;
                background-color: var(--card-background);
            }
        }

        .fc-timeline-slot{
            .fc-timeline-slot-frame{
                height: 40px !important;
                background-color: var(--card-background);
            }
        }

        // .fc-timeline-header{
        //     background-color: var(--card-background);
        // }
    }
}


.active-indicator {
    background-color: var(--success);
}

/* FULL CALENDAR */

.freya-event-edited{
    outline: 2px solid var(--warning);
    border-radius: 4px;
}

.freya-event-container{
    height: 50px;
    cursor: pointer;


    .freya-travel-time-start{
        border-radius: 4px 0px 0px 0px;
        overflow: hidden;
        height: 44px;
        display: inline-block;
    }

    .freya-travel-time-end{
        border-radius: 0px 4px 0px 0px;
        overflow: hidden;
        height: 44px;
        display: inline-block;
    }

    .event-area-bar{
        width: 100%;
        margin-top: -3px;
        height: 6px;
        border-radius: 0px 0px 4px 4px;
        display: block !important;
    }

    // .freya-event-length-editable:after{
    //     content: '\22EE';
    //     display: block;
    //     height: 44px;
    //     margin-top: 12px;
    //     font-size: 16px;
    //     float: right;
    //     margin-left: 4px;
    // }

}

.freya-event-draggable{
    cursor: move;
}

.freya-event-disabled {
    pointer-events: none;
}

.p-button:disabled {
    pointer-events: all;
}

.freya-event-disabled::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* semi-transparent black */
}

// Calendar Events
.freya-calendar-event{
    height: 44px;
    padding: 2px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    clear: both;
    vertical-align: top;

    .event-type-info {
        height: 18px;
        min-width: 16px;
        margin: 0px 2px 4px 4px;
        border-radius: 4px;
    }

    .info {
        // width: calc(100% - 16px);
        white-space: nowrap;
        margin-left: 2px;
        font-size: 1.1em;
        // text-overflow: ellipsis;
        // overflow-x: hidden;
    }

    .title {
        display: block;
        margin-top: -2px;
        font-weight: bold;
    }

    .postal {
        display: inline;
        font-size: 10px;
    }

    div.status {
        flex-grow: 1;
        z-index: 10;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 4px;
        padding-left: 4px;
        margin-left: 2px;
        // i {

        // }
        // position: absolute;
        // right: 2px;
        // top: calc(50% - 7px);
        // z-index: 10;
        // margin: -4.5px -2px;
        // padding: 4.5px;
    }
}





// Full Calendar Edits
/*
    These variables together edit the look of the timeline rows.
    If you edit one you will likely have to play around with the others to keep from graphical errors (overlaps etc.) occuring
*/
$event-height: 44px;
$row-offset: -12px;
$bg-margin: 12px;
$cell-cushion-top: 18px;
$cell-cushion-bottom: 28px;

// Makes background events opaque
.fc-bg-event {
    // opacity: 1 !important;
    margin-bottom: $bg-margin;
}

// Changes the spacing for the resource cells
.fc-datagrid-cell-cushion{
    padding: 0px !important;
    padding-left: 4px !important;
    padding-top: $cell-cushion-top !important;
    padding-bottom: $cell-cushion-bottom !important;
}

// Removes the weird cushion at the bottom of rows
tbody {
    .fc-datagrid-cell-frame{
        margin-bottom: $row-offset;
    }

    .fc-timeline-lane-frame{
        // background-color: #D8D8D8;
        margin-bottom: $row-offset;
    }
}

.fc-event-title-container{
    height: 40px;
    align-items: center;
}

// Ensures the custom event takes up the whole height
// .fc-timeline-event{
//     height: $event-height;
// }


// Removes annoying border styling from month events
.fc-h-event {
    border: 0px solid grey !important;
    background-color: transparent !important;
}

.fc-direction-ltr .fc-timeline-event.fc-event-end,
.fc-direction-ltr .fc-timeline-more-link {
    margin-right: 0px !important;
}

// CUSTOM EVENT CLASSES

.freya-travel-event {
    height: $event-height;
    cursor: pointer;
}

.freya-month-event{
    border-radius: 4px;
    overflow: hidden;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 4px;
}

// Adds border between asset rows for availability page
.fc-scrollgrid-sync-table > tbody > tr > td[data-resource-id^=A-]{
    border-top: 2px solid #aaaaaa !important;
}

.fc-scrollgrid-sync-table > tbody > tr > td[data-resource-id^=A-]{
    border-bottom: 2px solid #aaaaaa !important;
}

.freya-availability-event {
    height: 50px;
    margin-right: 0px !important;
    background-image: repeating-linear-gradient(
        -45deg, transparent,
        transparent 0.3em,
        var(--light-availability-color) 0.3em,
        var(--light-availability-color) 0.4em
    );
}

.freya-remove-availability-button{
    color: var(--danger);
    background-color: var(--surface-a);
    cursor: pointer;
    position: absolute;
    right: 8px;
    height: 20px;
}

.freya-availability-template{
    overflow: hidden;
    height: 50px;
    padding: 4px;
    cursor: pointer;
    border: 2px solid var(--primary-color-text);
    background-color: var(--surface-a);
    border-radius: 4px;
    color: var(--primary-color-text);

    .name {
        font-weight: bold;
        color: var(--blackOrWhite);
    }

    .dates {

    }

    .zones {

    }
}

.freya-event-hidden {
    display: none;
    visibility: hidden;
    height: 0px;

    .fc-h-event {
        border: none;
    }
}

.fc-timeline-event {
    padding: 0px !important;
}

.freya-fc-hover {
    margin-top: 24px !important;
    min-width: 100px;
    padding: 8px;
    z-index: 1010;

    .hover-title {
        display: block;
        font-weight: bold;
        text-align: center
    }

    .hover-start {}

    // background-color: black;
    // color: white;
    // position: absolute;
}

.freya-fc-hover::before {
    margin-left: -5px !important;
}

.freya-fc-hover:after {
    content: none !important;
}

.fc {
    .fc-toolbar {
        .fc-button {
            display: inline-block;
        }
    }
}

.fc-event:focus:after {
    // Removes after click effect on events
    content: none !important;
}

.fc-event-resizer {
    width: 10px !important;
    display: block !important;
    color:white;
    border-radius: 4px;
}

.fc-event-resizer:after{
        content: '\22EE';
        display: block;
        height: 44px;
        margin-top: 12px;
        font-size: 16px;
        float: right;
        position: relative;
        right: -3px;
    }

.fc-event-resizer:hover {
    background-color: var(--surface-d);
}

.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
    right: 0px !important;
}

.fc-daygrid-more-link{
        float: none !important;
        .content {
            text-align: center;
            margin: 4px;
            // border-radius: 4px;
            // border: 2px solid var(--primary-color);
            // padding: 0px 8px !important;
            // padding: 8px;
        }

        .total {
            color: black;
            font-weight: bold;
            font-size: 1rem;
            margin-bottom: 8px;
        }

        .badges-container{
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;

            div{
                margin: 2px;
                padding: 6px 8px;
                font-weight: bold;
                color: white;
                border-radius: 4px;
            }
        }

        .description{
            color: #425563;
            font-size: 0.75rem;
        }
}

.clickable {
    cursor: pointer;
}

.clickable-warning {
    display: inline-block;

    &:hover {
        cursor: pointer;
    }
}

.jobsv2-enabled-waring {
    display: flex;
    align-items: center;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.loading-cover{
    background: rgba(0, 0, 0, 0.3);
    color: #EAEBEC;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    i {
        margin-left: calc(50vw - 36px);
        margin-top: calc(50vh - 36px);
    }
}

.loading-cover.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s linear;
}

.loading-cover.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.25s, opacity 0.25s linear;
}

.change-zone-dialog .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
        margin: 0;
    }
}

.p-dialog-content img {
    max-width: 100%;
}

.resize-image{
    img{
        max-width:100%;
        vertical-align: middle;
    }
}

.change-zone-dialog.requireAnswer .p-dialog-header-close {
    display:none !important;
}

.menu-wrapper .layout-menu-container li a .layout-menuitem-text {
    // background-color: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.schedule-hover-container {
    position: absolute;
    > span {
        text-align: center;
    }
}

div.schedule-hover {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    span {
        text-align: center;
    }

    .schedule-hover-jobcode {
        font-weight: bold;
    }

    table {
        margin: 3px 0;

        td {
            text-align: center;
        }

        thead {
            font-weight: bold;
            td {
                padding: 1px 3px
            }

        }
    }

    .schedule-hover-locations .travel-to-next-destination {
        font-size: 80%;
        margin: 5px 0;
        text-align: center;
        font-weight: bold;
    }
}

.header-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    h3, h4, h5 {
        margin-bottom: 0;
        margin-top: 0;
    }

    >:nth-child(2) {
        margin-left: 0.5rem;
    }
}

.overlay-panel-date .p-overlaypanel-content {
    padding: 0 !important;


}

.overlay-panel-date.p-overlaypanel::before,
.overlay-panel-date.p-overlaypanel::after{
    content: none;
}

.dashboard-info .p-overlaypanel-content {
    max-width: 35rem;
}

//// Scrollbar ////
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--dark-grey);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #425574;
}

// Light Mode Colours
// :root{
//     --success: #689F38;
//     --info: #0288D1;
//     --warning: #FBC02D;
//     --help: #9C27B0;
//     --danger: #D32F2F;
// }


// 2fa dialog
.two-factor-dialog form {

    padding-top: 10px;

    input {
        font-size: 2em;
        text-align: center;
        margin: 0 0 20px 0;
    }

    small {
        font-size: 1em;
        margin-bottom: 5px;
        margin-top: -10px;
    }

    button {
        font-size: 1.5em;
        display: block;
        text-align: center;
        width: 100%;
    }

    width: 30em;
    max-width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.p-dialog.p-confirm-dialog {

    .p-confirm-dialog-message {
        white-space: pre-line;
        margin-left: 0;
    }

    .p-confirm-dialog-icon {
        margin-right: 1rem;
    }

}

.search {
    width: 100%;

    .p-inputtext {
        padding: 1rem 0.5rem;
        width: 100%;
    }

    .p-autocomplete-empty-message {
        padding: 0.5rem;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
        border: 1px solid var(--primary-lighter-color);
        padding: 1rem;
    }
}

.views .p-dropdown {
	border-radius: 6px 0 0 6px
}

// Primeflex got rid of this "prefix" eg p-100 in primeflex@3.0.0
// So we have to define it manually before we import primeflex.
$prefix: 'p-';
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";

@import "assets/demo/flags/flags.css";

@import "assets/icons/material/material.css";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
